/**
 * File: base
 * Project: api-client
 * Author: lents
 * Date: 9/15/20
 *
 * This is the basic stuff external hosts need to figure out how to reach Captina
 *  - may be defined if lz_utils is included, hence the checks
 */
import './polyfills'
import { cpHost } from './vars'
import axios from 'axios'

// XXX: Should be removed
const legacyJavaScriptPath = '/lz_js/'
const javascriptPath = '/injector/static/js/'
export const requiredJavascript = [
  // new injector
  'https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js',
  'utility.js',
  'click-logger.js',
  'alert-dialogs.js',
  // XXX: legacy stuff, should be deprecated
  'lz_utils_ui.js',
  'lz_utils.js'
]

function addRequiredJavascript () {
  // if some of the required scripts are already loaded, don't load them again
  if (
    [...document.scripts]
      .some(js => js.src && requiredJavascript.includes(js.src.split('/')
      .pop()
      ))
  ) {
    return
  }

  // requireScripts aren't loaded, so load them
  requiredJavascript.forEach(jsSrc => {

    let script = document.createElement('script'),
      // default to new injector path
      scriptUrl = `https://${cpHost}${javascriptPath}${jsSrc}`
    // if jsSrc is an absolute path, use that
    if (jsSrc.startsWith('http')) {
      scriptUrl = jsSrc
      script.async = true
    }
    // if jsSrc is a legacy script, use that path
    if (jsSrc.startsWith('lz_')) {
      scriptUrl = `https://${cpHost}${legacyJavaScriptPath}${jsSrc}`
    }
    // set script attributes
    script.type = 'text/javascript'
    script.crossOrigin = 'anonymous'
    script.src = scriptUrl

    // add utils to head, others to body
    const isHeadScript = ['lz_utils', 'axios']
      .some(
        (scriptFragment) => jsSrc.includes(scriptFragment)
      )
    if (isHeadScript) {
      script.async = true
      document.head.appendChild(script)
    }
    else if (!document.body) {
      script.defer = true
      setTimeout(() => {
        document.body.appendChild(script)
      }, 5000)
    }
    else {
      document.body.appendChild(script)
    }
  })
}

function addRootStylesheet() {
  // if the root stylesheet is already loaded, don't load it again
  if (
    [...document.styleSheets]
      .some(css => css?.href?.endsWith('/css/root.css'))
  ) {
    return // already in place
  }
  const rootStylesheet = document.createElement('link')
  rootStylesheet.rel = 'stylesheet'
  rootStylesheet.href = `https://${cpHost}/injector/static/css/root.css`
  rootStylesheet.type = 'text/css'
  rootStylesheet.media = 'all'
  rootStylesheet.crossOrigin = 'anonymous'
  document.head.appendChild(rootStylesheet)
}

addRequiredJavascript()
addRootStylesheet()
// window.axios = axios
